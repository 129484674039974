import React, { FC, ReactElement } from "react";
import { useGetIdentity } from "@refinedev/core";

const PermissionShowing: FC<{
  enabled: string[];
  children: ReactElement;
  nonPermission?: ReactElement;
}> = ({ enabled, children, nonPermission }) => {
  const { data: accountInfo, status } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true,
  });

  return children;

  if (status === "success") {
    return enabled.includes(accountInfo.role) ? (
      children
    ) : (
      <>{nonPermission && nonPermission}</>
    );
  }

  return <>Loading...</>;
};

export default PermissionShowing;
